import './App.css';
import Body from './component/Body';
import Header from './component/Header';

function App() {
  return (
    <div>
      {/* <Header/> */}
      <Body/>
    </div>
  );
}

export default App;
