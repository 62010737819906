import React from 'react'
import '../App.css'
import Header from './Header'

function Content() {
  return (
    <div className="container-fuild content">
        <Header/>
    </div>
  )
}

export default Content