import React from 'react'
import '../App.css'
import LeftNav from './LeftNav'
import Content from './Content'

function Body() {
  return (
    <div className="container-fluid body1">
        <LeftNav/>
        <Content/>
    </div>
  )
}

export default Body