import React from 'react'
import '../App.css'

function Header() {
  return (
    <div className="container-fluid header">
        
        <div className="header-items">
        <div className="greet">
        <h1>Hello User!</h1>
        </div>
            <div className="user-icon"></div>
        </div>
    </div>
  )
}

export default Header